import React from 'react'
import Seo from "../components/molecules/Seo"
import Container from '../components/atoms/Container'
import styled, { withTheme } from "styled-components"
import ContentHolder from "../components/organisms/ContentHolder"
import Splash from "../components/organisms/Splash"
import ArrowText from "../components/atoms/ArrowText"
import XmasCardSelect from '../components/molecules/XmasCardSelect'
import Illustration from "../assets/xmas-banner.inline.svg"
import videoSrc from '../../static/videos/pixray-demo.mp4'
import thumb from "../../static/images/xmas-images/renaissance-presents-1.png"
import vidThumb from "../../static/images/xmas-images/pixray-thumb.png"

const SplashText = styled.p`
  margin-top: 4rem;
`;

const IlloHolder = styled.div`
  margin: 4rem 0 ;
  height: 6rem;
  position: relative;
  .inner {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    transform: translateX(-50%);
    display: flex;
    svg {
      height: 100%;
      width: auto;
    }
  }
`;

const VidSection = styled.div`
  background: white;
  padding: 0 0 4rem;
  video {
    margin: -6rem 0 1rem;
    clip-path: inset(1px 1px);
    @media (${(props) => props.theme.breakpoints.sm}) {
      margin: -8.5rem 0 1rem;
    }
  }
`;


const Christmas = ({ theme }) => (
    <>
      <Seo
        title="Christmas Card Generator | Craig Walker"
        thumb={thumb}
        description="Choose from the options below, and we’ll generate a unique, Christmas-themed artwork with Artificial Intelligence"
      />
      <Splash
        noPadding
        bg={theme.colours.xmas}
        text={
          <>
            <b>Generate real joy</b><br />
            with AI
          </>
        }
      >
        <SplashText>
        To celebrate the festive season this year, Craig Walker is putting AI to work for you.
        <br/><br/>We know this time of year is busy and finding the right card often tricky, so we’ve created a platform* that uses AI to create a holiday card from a text-based description. 
        <br/><br/>Select a description below and choose the style you’d like your card to reflect, then watch the AI work its creative magic.
        </SplashText>
        <XmasCardSelect />
        <ArrowText>
          <p>watch the AI work <br />its creative magic</p>
        </ArrowText>
        <IlloHolder>
          <div className="inner">
            <Illustration />
          </div>
        </IlloHolder>
        <h4 style={{fontFamily: '"Lora",serif', marginBottom: '4rem'}}>Watch the magic happen</h4>
      </Splash>
      <VidSection>
        <ContentHolder>
          <Container>
            <video
            loop
            playsInline
            controls
            poster={vidThumb}
            src={videoSrc}
            type={"video/mp4"} />
            <p>*The platform uses <b><a href="https://github.com/pixray/pixray" target="_blank" rel="noreferrer">Pixray</a></b>, a project that uses an image-generating algorithms to turn text-based descriptions into digital works of art.</p>
          </Container>
        </ContentHolder>
      </VidSection>
    </>
  )
  
  export default withTheme(Christmas)
