import React, {Fragment} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Holder = styled.section`
  > :first-child { margin-top: 0; }
`;

function ContentHolder( { children } ) {
  return (
    <Fragment>
      <Holder className="content-holder">
        {children}
      </Holder>
    </Fragment>
  )
}

ContentHolder.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ContentHolder;
