import React, {useState} from 'react'
import styled from "styled-components"
import { Link } from 'gatsby';
import IconArrow from '../atoms/iconArrow';
import { Collapse } from 'antd';
const { Panel } = Collapse;

const Holder = styled.div`
    .ant-collapse-content-inactive{ display: none;}
    .ant-collapse > .ant-collapse-item {
        padding: .5rem 0;
        border-bottom: 1px solid #000;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header {
        padding: 0;
        
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
        transform: rotate(90deg);
    }
    .ant-collapse-item-active > .ant-collapse-header .ant-collapse-arrow svg {
        transform: rotate(270deg) !important;
    }
    .ant-collapse-content-box {
        padding: 0;
    }
    
    button {
        cursor: pointer;
        color: #B2874B;
        background-color: inherit;
        padding: 0;
        margin: 0.75rem 0;
        border: none;
        line-height: 1.3;
        text-transform: revert;
        font-weight: normal;
        display: block;
        font-size: ${props => props.theme.fluidType( 0 )};
        
        &.item-active {
            color: #000;
        }
        &:hover {
            color: #000;
        }
    }
    
    .ant-collapse-header {
        font-size: ${props => props.theme.fluidType( 0 )};
        color: #B2874B !important;
        svg {
            color: #000 !important;
        }
    }
    .activity-active .ant-collapse-header  {
        color: #000 !important;
    }
`;

const LinkHolder = styled.div`
  padding: 1rem 0; 
  font-size: ${props => props.theme.fluidType( 0 )};
  font-weight: 600;
  a {
    display: flex;
    align-items: center;
    span {
      margin-right: 1rem;
    }
  }
  path {
    stroke: black;
  }
  &.block-click {
    a {
      color: #B2874B;
      pointer-events: none;
      cursor: not-allowed;
    }
    path {
      stroke: #B2874B !important;
    }
  }
`;

function XmasCardSelect() {
    const [activity, setActivity] = useState('');
    const [activitySlug, setActivitySlug] = useState('');
    const [style, setStyle] = useState('');
    const [styleSlug, setStyleSlug] = useState('');

    let activityHeader = "Select a festive activity";
    if(activity !== ''){
        activityHeader = activity;
    }
    let styleHeader = "Select an artistic style";
    if(style !== ''){
        styleHeader = style;
    }

    const closeActive = () => {
        var x = document.querySelectorAll(".ant-collapse-item-active");    
        if(x.length){
            for(var i=0; i<x.length; i++) { 
                    setTimeout(function () {
                    var el = document.querySelector(".ant-collapse-item-active");        
                    el.children[0].click();  
                }, 100);          
            }
        }
    } 

    const handleActivityClick = (e, text, slug) => {
        setActivity(text)
        setActivitySlug(slug)
        closeActive()

    }
    const handleStyleClick = (e, text, slug) => {
        setStyle(text)
        setStyleSlug(slug)
        closeActive()
    }
    const link = `/card?activity=${activitySlug}&style=${styleSlug}`
    const canProceed = (style !== '' && activity !== '')
    return (
        <Holder>
            <Collapse ghost accordion>
                <Panel header={activityHeader} key="1" className={activity !== '' ? 'activity-active' : ''  }>
                    <button className={activitySlug === 'mistletoe' ? 'item-active' : ''} onClick={(e) => handleActivityClick(e,'Kissing under the mistletoe', 'mistletoe')}>Kissing under the mistletoe</button>
                    <button className={activitySlug === 'naughty' ? 'item-active' : ''} onClick={(e) => handleActivityClick(e,"Listing who's naughty or nice", 'naughty')}>Listing who&rsquo;s naughty or nice</button>
                    <button className={activitySlug === 'jolly' ? 'item-active' : ''} onClick={(e) => handleActivityClick(e,'Having a holly jolly Christmas', 'jolly')}>Having a holly jolly Christmas</button>
                    <button className={activitySlug === 'tree' ? 'item-active' : ''} onClick={(e) => handleActivityClick(e,'Lighting up the tree', 'tree')}>Lighting up the tree</button>
                    <button className={activitySlug === 'sleigh' ? 'item-active' : ''} onClick={(e) => handleActivityClick(e,"Riding Santa's sleigh", 'sleigh')}>Riding Santa&rsquo;s sleigh</button>
                    <button className={activitySlug === 'elves' ? 'item-active' : ''} onClick={(e) => handleActivityClick(e,'Surfing with the elves', 'elves')}>Surfing with the elves</button>
                    <button className={activitySlug === 'presents' ? 'item-active' : ''} onClick={(e) => handleActivityClick(e,'Ripping wrapping off presents', 'presents')}>Ripping wrapping off presents</button>
                    <button className={activitySlug === 'crackers' ? 'item-active' : ''} onClick={(e) => handleActivityClick(e,'Popping crackers and champagne', 'crackers')}>Popping crackers and champagne</button>
                    <button className={activitySlug === 'dashing' ? 'item-active' : ''} onClick={(e) => handleActivityClick(e,'Dashing through the snow ', 'dashing')}>Dashing through the snow</button>
                    <button className={activitySlug === 'cricket' ? 'item-active' : ''} onClick={(e) => handleActivityClick(e,'Playing backyard cricket', 'cricket')}>Playing backyard cricket</button>
                </Panel>
                <Panel header={styleHeader} key="2" className={style !== '' ? 'activity-active' : ''  }>
                    <button className={styleSlug === 'impressionism' ? 'item-active' : ''} onClick={(e) => handleStyleClick(e,'Impressionism', 'impressionism')}>Impressionism</button>
                    <button className={styleSlug === 'renaissance' ? 'item-active' : ''} onClick={(e) => handleStyleClick(e,'Renaissance', 'renaissance')}>Renaissance</button>
                    <button className={styleSlug === 'pop' ? 'item-active' : ''} onClick={(e) => handleStyleClick(e,'Pop Art', 'pop')}>Pop Art</button>
                    <button className={styleSlug === 'modern' ? 'item-active' : ''} onClick={(e) => handleStyleClick(e,'Modern Art', 'modern')}>Modern Art</button>
                    <button className={styleSlug === 'surreal' ? 'item-active' : ''} onClick={(e) => handleStyleClick(e,'Surrealism', 'surreal')}>Surrealism</button>
                </Panel>
            </Collapse>
            <LinkHolder className={!canProceed ? 'block-click' : ''} >
                <Link to={link} ><span>Let us work our magic</span><IconArrow /></Link>
            </LinkHolder>
        </Holder>
    )
}

export default XmasCardSelect
